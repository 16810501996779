import Axios from 'axios';
import { Client } from './clientsApi';
import { User } from './userApi';
import { z } from 'zod';
import { FormItemSchema } from 'components/FormBuilder/FormBuilderContext';

export interface JobForm {
  id?: string;
  jobId: string;
  templateName: string;
  templateVersion: string;
  formItems: any;
  inputData: any;
  lastUpdatedOn: string;
  lastUpdatedByUser?: User;
  addedOn?: string;
  isSubmitted?: boolean;
  isCustomized?: boolean;
  customizedOn?: string;
  customizedByUserId?: string;
  customizedByUser?: User;
}

export interface FormTemplateGroup {
  id: number;
  groupName: string;
  associatedClient?: Client;
  formTemplates?: FormTemplate[];
}

export interface FormTemplate {
  id: string;
  templateName: string;
  formItems: any;
  updatedOn: string;
  updatedByUser?: User;
  version?: number;
  isDefaultForm?: boolean;
}

export interface FormTemplateGroupMap {
  organizationId: number;
  groupName: string;
  associatedClient?: Client;
  formTemplates?: VersionedFormTemplate[];
  id: string;
}

export interface VersionedFormTemplate {
  templates?: FormTemplate[];
  templateName: string;
}
export interface FormTemplateCreateRequest {
  formItems: any;
  templateName: string;
  formTemplateGroupId?: number;
  version?: number;
}

export const MultiSignatureObjectSchema = z.object({
  id: z.string(),
  sign: z.string(),
  signedBy: z.string(),
});

export type MultiSignatureObject = z.infer<typeof MultiSignatureObjectSchema>;

export async function saveNewFormTemplate({
  templateName,
  formTemplateGroupId,
  formItems,
  version,
}) {
  const payload: FormTemplateCreateRequest = {
    templateName,
    formTemplateGroupId,
    formItems,
  };
  if (version) {
    payload.version = parseInt(version);
  }
  const res = await Axios.post('/api/v1/form/templates', {
    templateName,
    formTemplateGroupId,
    formItems,
    version,
  });
  return res.data;
}

export async function listFormTemplates(): Promise<FormTemplate[]> {
  const res = await Axios.get('/api/v1/form/templates');
  return res.data;
}

export async function listJobForms(jobId: string): Promise<JobForm[]> {
  const res = await Axios.get(`/api/v2/form/jobforms/${jobId}`);
  return res.data;
}

export async function addFormToJob(
  jobId: string,
  data: {
    formTemplateId: string;
  }
) {
  const res = await Axios.post(`/api/v1/form/jobForms/${jobId}`, data);
  return res.data;
}

export async function submitFormData(
  jobId: string,
  formId: string,
  inputData: any
) {
  const res = await Axios.put(
    `/api/v1/form/jobForms/${jobId}/${formId}`,
    inputData
  );
  return res.data;
}

export async function customizeFormInsideJob({
  jobId,
  formId,
  formItems,
}: {
  jobId: string;
  formId: string;
  formItems: any[];
}) {
  const res = await Axios.put(
    `/api/v1/form/jobforms/customization/${jobId}/${formId}`,
    formItems
  );
  return res.data;
}

export async function listFormTemplateGroups({
  loadTemplates = false,
}: {
  loadTemplates: boolean;
}): Promise<FormTemplateGroupMap[]> {
  const res = await Axios.get(
    `/api/v1/form/template-groups?loadTemplates=${loadTemplates}`
  );
  return res.data;
}

// new Api (v2) to get form template groups for a job
export async function listFormTemplateGroupsForJobs({
  jobId,
}: {
  jobId: string;
}): Promise<FormTemplateGroupMap[]> {
  const res = await Axios.get(`/api/v2/form/template-groups/${jobId}`);
  return res.data;
}

export async function addNewFormTemplateGroup({
  groupName,
  associatedClientId,
}: {
  groupName: string;
  associatedClientId?: string;
}): Promise<Partial<FormTemplateGroup>> {
  const res = await Axios.post('/api/v1/form/template-groups', {
    groupName,
    associatedClientId,
  });
  return res.data;
}

export async function updateFormTemplateGroup({
  id,
  groupName,
  associatedClientId,
}: {
  id: number;
  groupName: string;
  associatedClientId?: string;
}): Promise<Partial<FormTemplateGroup>> {
  const res = await Axios.put(`/api/v1/form/template-groups/${id}`, {
    groupName,
    associatedClientId,
  });
  return res.data;
}

export async function createDefaultForms(jobId: string) {
  const res = await Axios.post(`/api/v1/form/job-forms/${jobId}/default-forms`);

  return res.data;
}

export async function submitFormDataWithAdditionalSignature({
  jobId,
  formId,
  newSignature,
  referencedSignatureId,
}: {
  jobId: string;
  formId: string;
  newSignature: MultiSignatureObject[];
  referencedSignatureId: string;
}) {
  const res = await Axios.put(
    `/api/v2/form/jobForms/${jobId}/${formId}/additional-signature`,
    {
      newSignature,
      referencedSignatureId,
    }
  );

  return res.data;
}
