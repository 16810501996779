import React, { useState, useEffect } from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import WifiOffIcon from '@mui/icons-material/WifiOff';

const OfflineModel = () => {
  const [showOfflineWarning, setShowOfflineWarning] = useState(false);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setShowOfflineWarning(!navigator.onLine);
    };

    // Add event listeners to listen for online/offline status changes
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    console.log('showOfflineWarning', showOfflineWarning);

    return () => {
      // Remove event listeners when the component unmounts
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showOfflineWarning ? (
        <Dialog
          open={true}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              zIndex: 10000,
              position: 'absolute',
            },
          }}
          fullWidth>
          <Box
            sx={{
              backgroundColor: 'white',
              color: 'black',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 3,
              flexDirection: 'column',
              gap: 2,
              borderRadius: 2,
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}>
            <WifiOffIcon sx={{ color: 'black', fontSize: 48 }} />
            <Typography
              sx={{ color: 'black', fontSize: 18, fontWeight: 'bold' }}>
              Oops! You’re currently offline
            </Typography>
            <Typography sx={{ color: 'black', fontSize: 14 }}>
              We couldn't detect a connection. Please check your internet
              connection and try again.
            </Typography>
          </Box>
        </Dialog>
      ) : null}
    </>
  );
};

export default OfflineModel;
