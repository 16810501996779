import Axios from 'axios';
import { User } from './userApi';

export interface Upload {
  id: string;
  jobId: string;
  name: string;
  mimeType: string;
  createdOn: string;
  uploadByUserId: string;
  uploadByUser?: User;
  comments?: string;
}

export async function listJobUploads(jobId: string) {
  const res = await Axios.get<Upload[]>(`/api/v1/uploads/job/${jobId}`);
  return res.data;
}

export async function uploadJobFiles({
  jobId,
  files,
  onUploadProgress,
}: {
  jobId: string;
  files: File[];
  onUploadProgress: (e: ProgressEvent) => void;
}) {
  const formData = new FormData();
  files.forEach((file) => formData.append('files', file));
  const res = await Axios.post(`/api/v1/uploads/job/${jobId}`, formData, {
    onUploadProgress,
  });
  return res.data;
}

export async function getPreviewLink(jobId: string, fileId: string) {
  const res = await Axios.get(`/api/v1/uploads/job/${jobId}/${fileId}/url`);
  return res.data;
}

export async function getDownloadUploadedFile(jobId: string, fileId: string) {
  const res = await Axios.get(
    `/api/v1/uploads/uploaded-file-download/${jobId}/${fileId}`
  );
  return res.data;
}

export async function updateUploadDetails({ jobId, uploadId, name, comments }) {
  const res = await Axios.put(`/api/v1/uploads/job/${jobId}/${uploadId}`, {
    name,
    comments,
  });
  return res.data;
}

export async function deleteUploads(jobId: string, uploadIds: string[]) {
  const promises = uploadIds.map((id) =>
    Axios.delete(`/api/v1/uploads/job/${jobId}/${id}`)
  );
  return await Promise.all(promises);
}
