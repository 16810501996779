import React, { useContext } from 'react';
import { CalendarEvent } from './Calendar';
import {
  Typography,
  makeStyles,
  Tooltip,
  withStyles,
  Box,
} from '@material-ui/core';
import CalendarContext from './CalendarContext';
import clsx from 'clsx';
import stringToColor from 'util/stringToColor';
import { blue } from '@material-ui/core/colors';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

const useStyles = makeStyles(() => ({
  title: {
    color: 'inherit',
    fontSize: '13px',
  },
  monthViewTitle: {
    fontSize: '12px',
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 10px 30px 0px rgba(193, 193, 193, 0.30)',
    fontSize: 11,
    padding: 15,
  },
}))(Tooltip);

const EventCard = React.memo(({ event }: { event: CalendarEvent }) => {
  const { view } = useContext(CalendarContext);
  const classes = useStyles();

  return (
    <LightTooltip
      arrow
      disableHoverListener={['day', 'agenda'].includes(view)}
      placement="right-start"
      title={
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            paddingLeft: 10,
            paddingRight: 10,
          }}>
          <Typography variant="subtitle1" style={{ color: blue[800] }}>
            <b>{event.name.toUpperCase()}</b>
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="subtitle2" gutterBottom>
              Assignees
            </Typography>
            <ul>
              {event.taskAssignments?.map(({ user }, i) => (
                <li key={i}>
                  <Typography variant="caption">{`${user.firstName} ${user.lastName}`}</Typography>
                </li>
              ))}
            </ul>
          </Box>
          {event.description && (
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="subtitle2" gutterBottom>
                Description
              </Typography>
              <Typography variant="caption">
                {truncateText(event.description)}
              </Typography>
            </Box>
          )}
        </Box>
      }>
      <Box style={{ height: '100%' }}>
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant="subtitle2"
            className={clsx({
              [classes.title]: true,
              [classes.monthViewTitle]: view === 'month',
            })}
            gutterBottom>
            <b>{event.name}</b>
          </Typography>
          {event.isRecurring && view !== 'agenda' && (
            <EventRepeatIcon
              style={{
                fontSize: '0.9rem',
                marginTop: 2,
                color: stringToColor(
                  event.taskAssignments[0]?.user?.firstName,
                  800
                ),
              }}
            />
          )}
        </Box>
        {view !== 'month' && (
          <Typography variant="body2" color="secondary">
            {event.job?.siteAddress}
          </Typography>
        )}
      </Box>
    </LightTooltip>
  );
});

export const getEventProps = (event: CalendarEvent) => {
  const color = stringToColor(
    event.taskAssignments[0]?.user?.firstName || 'user',
    100
  );
  return {
    style: {
      backgroundColor: color,
      borderTop:
        event.job &&
        `2px solid ${stringToColor(
          event.taskAssignments[0]?.user?.firstName,
          400
        )}`,
      color: 'black',
    },
  };
};

const truncateText = (text: string) => {
  if (text?.length > 200) {
    return text.slice(0, 200) + '...';
  }
  return text;
};

export default EventCard;
