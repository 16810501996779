import React, { useState } from 'react';
import clsx from 'clsx';
import Topbar from './Topbar';
import Sidebar from './Sidebar/Sidebar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import OfflineModel from 'OfflineModel';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 240,
    },
  },
  content: {
    height: '100%',
  },
}));

interface Props {
  children: JSX.Element | JSX.Element[];
}

const MainLayout = ({ children }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [openSidebar, setOpenSidebar] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  // const shouldOpenSidebar = isDesktop ? true : openSidebar;
  const shouldOpenSidebar = openSidebar;

  const [offline, setOffline] = useState(!navigator.onLine);

  window.addEventListener('offline', () => setOffline(true));
  window.addEventListener('online', () => setOffline(false));

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: openSidebar,
      })}>
      <Topbar
        onSidebarOpen={() => setOpenSidebar(true)}
        onSidebarClose={() => setOpenSidebar(false)}
        sidebarIsOpen={openSidebar}
      />
      {offline && <OfflineModel />}
      <Sidebar
        data-cy={'side-bar'}
        onClose={() => setOpenSidebar(false)}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default MainLayout;
