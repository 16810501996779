import Axios from 'axios';
import download from 'downloadjs';

export enum ReportType {
  INVENTORY_DETAIL_REPORT = 'inventory-detail-report',
  INVENTORY_PHOTO_REPORT = 'inventory-photo-report',
  INITIAL_ATTENDANCE_REPORT = 'initial-attendance-report',
  PHOTO_REPORT = 'photo-report',
  DYNAMIC_REPORT = 'dynamic-report',
  SITE_VISIT_REPORT = 'site-visit-report',
  COMPLETION_REPORT = 'completion-report',
  EQUIPMENT_STATUSES_REPORT = 'equipment-statuses-report',
}

export async function downloadAttendanceReport(
  jobId: string,
  letterheadId?: string,
  showJobDetailsHeader?: boolean
): Promise<void> {
  const searchParams = new URLSearchParams(
    `letterheadId=${letterheadId}&showJobDetailsHeader=${showJobDetailsHeader}`
  ).toString();

  const res = await Axios.get(
    `/api/v1/reports/${jobId}/${
      ReportType.INITIAL_ATTENDANCE_REPORT
    }${`?${searchParams}`}`,
    {
      responseType: 'arraybuffer',
    }
  );
  return res.data;
}

export async function downloadInventoryDetailReport(
  jobId: string,
  roomIds?: string[],
  dispositions?: string[],
  showJobDetailsHeader?: boolean
): Promise<void> {
  const searchParams = new URLSearchParams(
    `showJobDetailsHeader=${showJobDetailsHeader}`
  ).toString();
  const res = await Axios.post(
    `/api/v1/reports/${jobId}/${
      ReportType.INVENTORY_DETAIL_REPORT
    }${`?${searchParams}`}`,
    { roomIds, dispositions },
    {
      responseType: 'arraybuffer',
    }
  );
  return res.data;
}

export async function downloadInventoryPhotoReport(
  jobId: string,
  roomIds?: string[],
  dispositions?: string[],
  showJobDetailsHeader?: boolean,
  letterheadId?: string
): Promise<void> {
  const searchParams = new URLSearchParams(
    `showJobDetailsHeader=${showJobDetailsHeader}&letterheadId=${letterheadId}`
  ).toString();
  const res = await Axios.post(
    `/api/v1/reports/${jobId}/${
      ReportType.INVENTORY_PHOTO_REPORT
    }${`?${searchParams}`}`,
    { roomIds, dispositions },
    {
      responseType: 'arraybuffer',
    }
  );
  return res.data;
}

export async function downloadPhotoReport({
  jobId,
  feedItemIDs,
  letterheadId,
  showPhotoNoteHeader,
  showJobDetailsHeader,
}: {
  jobId: string;
  feedItemIDs: any[];
  fileName?: string;
  letterheadId: string;
  showPhotoNoteHeader?: boolean;
  showJobDetailsHeader?: boolean;
}) {
  const searchParams = new URLSearchParams(
    `letterheadId=${letterheadId}&showPhotoNoteHeader=${showPhotoNoteHeader}&showJobDetailsHeader=${showJobDetailsHeader}`
  ).toString();

  const res = await Axios.post(
    `/api/v1/reports/${jobId}/${ReportType.PHOTO_REPORT}${`?${searchParams}`}`,
    { feedItemIDs },
    {
      responseType: 'arraybuffer',
    }
  );
  return res.data;
}

export async function downloadDynamicReport({
  jobId,
  formId,
  fileName,
  letterheadId,
  showJobDetailsHeader,
}: {
  jobId: string;
  formId: string;
  fileName: string;
  letterheadId?: string;
  showJobDetailsHeader?: boolean;
}) {
  const searchParams = new URLSearchParams(
    `letterheadId=${letterheadId}&showJobDetailsHeader=${showJobDetailsHeader}`
  ).toString();

  const res = await Axios.get(
    `/api/v1/reports/${jobId}/${
      ReportType.DYNAMIC_REPORT
    }/${formId}${`?${searchParams}`}`,
    {
      responseType: 'arraybuffer',
    }
  );

  const mime = res.headers['Content-Type'];
  download(res.data, fileName, mime);
}

export async function downloadSiteVisitReport({
  jobId,
  inspectionId,
  letterheadId,
  showJobDetailsHeader,
}: {
  jobId: string;
  inspectionId: string;
  letterheadId: string;
  showJobDetailsHeader?: boolean;
}) {
  const searchParams = new URLSearchParams(
    `letterheadId=${letterheadId}&showJobDetailsHeader=${showJobDetailsHeader}`
  ).toString();

  const res = await Axios.get(
    `/api/v1/reports/${jobId}/${inspectionId}/${
      ReportType.SITE_VISIT_REPORT
    }${`?${searchParams}`}`,
    {
      responseType: 'arraybuffer',
    }
  );

  return res.data;
}

export async function downloadCompletionReport({
  jobId,
  letterheadId,
  showJobDetailsHeader,
}: {
  jobId: string;
  letterheadId: string;
  showJobDetailsHeader?: boolean;
}) {
  const searchParams = new URLSearchParams(
    `letterheadId=${letterheadId}&showJobDetailsHeader=${showJobDetailsHeader}`
  ).toString();

  const res = await Axios.get(
    `/api/v1/reports/${jobId}/${
      ReportType.COMPLETION_REPORT
    }${`?${searchParams}`}`,
    {
      responseType: 'arraybuffer',
    }
  );

  return res.data;
}

export async function downloadEquipmentStatusesReport({
  fileName,
}: {
  fileName: string;
}) {
  const res = await Axios.get(
    `/api/v1/reports/${ReportType.EQUIPMENT_STATUSES_REPORT}`,
    {
      responseType: 'arraybuffer',
    }
  );

  const mime = res.headers['Content-Type'];
  download(res.data, fileName, mime);
}
