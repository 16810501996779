import Axios from 'axios';
import { User } from './userApi';

export enum JobReportStatusType {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}
export interface JobReportsResponse {
  createdByUser: User;
  id: string;
  jobId: string;
  organizationId: number;
  reportName: string;
  reportVersion: number;
  isFailed?: boolean;
  createdOn: string;
  updatedOn: string;
  gcsPath?: string;
  fileByteSize?: number;
  generatedOn?: string;
  jobReportStatus: JobReportStatusType;
}

export enum JobReportType {
  Attendance_Report = 'InitialAttendanceReport',
  Completion_Report = 'CompletionReport',
  Inventory_Detail_Report = 'InventoryDetailReport',
  Inventory_Photo_Report = 'InventoryPhotoReport',
  Photo_Report = 'PhotoReport',
  Site_Visit_Report = 'SiteVisitReport',
}

export enum JobFileDownloadType {
  PHOTO_NOTES_DOWNLOAD = 'PHOTO_NOTES_DOWNLOAD',
  ATTENDANCE_REPORT_DOWNLOAD = 'ATTENDANCE_REPORT_DOWNLOAD',
  COMPLETION_REPORT_DOWNLOAD = 'COMPLETION_REPORT_DOWNLOAD',
  INVENTORY_REPORT_DOWNLOAD = 'INVENTORY_REPORT_DOWNLOAD',
  SITE_VISIT_REPORT_DOWNLOAD = 'SITE_VISIT_REPORT_DOWNLOAD',
}

export async function listJobReports(
  jobId: string,
  refetchRef: React.MutableRefObject<{
    refetchCount: number;
    isQueryInitialized: boolean;
  }>
) {
  const res = await Axios.get(`/api/v1/job-reports/${jobId}`);
  refetchRef.current.refetchCount++;
  return res.data;
}

export async function downloadJobReport(jobId: string, id: string) {
  const res = await Axios.get(`/api/v1/job-reports/${jobId}/${id}`);
  return res.data;
}
